import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'
import type {
	WaPackageDto,
	HvacFromQueryResponse,
	WoltAdvisorFromQueryRequest,
	FveVariant,
	WoltAdvisorFveResponse,
	WoltAdvisorFveRequest
} from '$lib/types/woltadvisor'

export type MarketplaceWatchdogModal = {
	open: boolean
	step: 'FORM' | 'THANK_YOU'
	leadSource?: string
	device?: string
	userInput?: {
		email?: string
		phone?: string
		zip?: string
	}
	marketplaceInput?: {
		formType?: 'hvac' | 'pv'
		catalogPackageIds?: string[]
		email?: string
		phone?: string
		zip?: string
		personalMeeting?: boolean
		installationProductCategory?: 'TEPELNE_CERPADLO' | 'FOTOVOLTAIKA'
		packageSetData?: string
		packageCalculatedFor?: string
		quickInstall?: boolean
	}
	packageData?: {
		fullpackageHvac?: {
			advisor: HvacFromQueryResponse
			initialData: WoltAdvisorFromQueryRequest
			package: WaPackageDto
		}
		fullpackagePv?: {
			advisor: WoltAdvisorFveResponse
			initialData: WoltAdvisorFveRequest
			package: FveVariant
		}
	}
}

const defaults: MarketplaceWatchdogModal = {
	open: false,
	step: 'FORM'
}

const contextName = 'MARKETPLACE_WATCHDOG_MODAL_CTX'

export function setMarketplaceWatchdogModalState(
	data?: MarketplaceWatchdogModal
): Writable<MarketplaceWatchdogModal> {
	const state = writable<MarketplaceWatchdogModal>({
		...defaults,
		...(data && { ...data })
	})
	setContext(contextName, state)
	return state
}

export function getMarketplaceWatchdogModalState(): Writable<MarketplaceWatchdogModal> {
	return getContext<Writable<MarketplaceWatchdogModal>>(contextName)
}
